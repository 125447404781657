import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthService } from '@fundo/app/services/auth.service';
import { filter, pairwise } from 'rxjs/operators';

import { FoPageRoute, NavigationService } from './navigation.service';

@Injectable()
export class RouterService {
  public currentUrl: string = '';
  public previousUrl: string = '';

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
  ) {}

  public checkUrl(): void {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
        if (
          this.currentUrl === FoPageRoute.NewForm ||
          this.currentUrl === FoPageRoute.AppForm
        ) {
          switch (this.previousUrl) {
            case FoPageRoute.ErrorPage:
            case FoPageRoute.ManualReviewPage:
            case FoPageRoute.DeniedPage:
            case FoPageRoute.OfferPage:
              this.authService.clearCurrentSession();
              this.navigationService.navigateToMainPage();
              break;
            case FoPageRoute.Main:
              this.authService.clearCurrentSession();
              break;
          }
        } else if (
          this.currentUrl === FoPageRoute.NewForm &&
          this.previousUrl === FoPageRoute.AppForm
        ) {
          this.authService.clearCurrentSession();
        } else if (
          this.currentUrl === FoPageRoute.AppForm &&
          this.previousUrl === FoPageRoute.NewForm
        ) {
          this.authService.clearCurrentSession();
        } else if (
          this.currentUrl !== FoPageRoute.AppForm &&
          this.currentUrl !== FoPageRoute.NewForm &&
          (this.previousUrl === FoPageRoute.ErrorPage ||
            this.previousUrl === FoPageRoute.ManualReviewPage ||
            this.previousUrl === FoPageRoute.DeniedPage)
        ) {
          this.authService.clearCurrentSession();
          this.navigationService.navigateToMainPage();
          return;
        }
      });
  }
}
