import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@fundo/app/services/auth.service';
import { environment } from '@fundo/environments/environment';
import { Store } from '@ngrx/store';
import { AlertService } from '@shared/services/alert.service';
import { NavigationService } from '@shared/services/navigation.service';
import { catchError, first, ObservableInput, of, switchMap, throwError } from 'rxjs';

import { selectUserIp } from '../states/user/selector/user.selector';
import { UserState } from '../states/user/user.state';

/**
 * Add fields to request's header
 * @param request the request to be modified
 * @param token string token
 * @param ip user's ip
 * @returns a new request with the previous data and new headers
 */
const addRequestHeaders = (
  request: HttpRequest<any>,
  token: string,
  ip: string = '',
): HttpRequest<any> => {
  return request.clone({
    setHeaders: {
      'Content-Type': request.headers.get('Content-Type') || 'application/json',
      'Authorization': `Bearer ${token}`,
      ...(request.url.startsWith(environment.apiBaseUrl) ? { 'ngsw-bypass': '' } : {}),
      'c-origin-address': ip,
    },
    setParams: {
      ...(request.method === 'GET'
        ? { hash: Date.now().toString(36) + Math.random().toString(36) }
        : {}),
    },
  });
};

/**
 * Function to manage error state, on 401 redirects to ERROR PAGE
 * @param error error throwed
 * @returns an error
 */
const catchRequestError = (
  navigationService: NavigationService,
  alertService: AlertService,
  error: any,
): ObservableInput<any> => {
  // TODO => Refresh token
  if (error instanceof HttpErrorResponse && error.status === 401) {
    navigationService.navigateToErrorPage();
  } else {
    // TODO: Solve Refresh Token error
    // ==========================
    const isRefreshTokenError =
      error &&
      error.error &&
      error.error.Errors &&
      error.error.Errors.includes('invalid_grant');
    if (isRefreshTokenError) return of(null);
    alertService.error('An unexpected server error has occurred. Please, try again.');
    // ==========================
    return throwError(() => error);
  }
  return of(null);
};

/**
 * Token function
 */
export const tokenInterceptorFn: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
) => {
  // Add token to API calls
  if (request.url.startsWith(environment.apiBaseUrl)) {
    const alertService = inject(AlertService);
    const authService = inject(AuthService);
    const navigationService = inject(NavigationService);
    const store = inject(Store<UserState>);

    return store.select(selectUserIp).pipe(
      first(),
      switchMap((ip: string) => {
        const newRequest = addRequestHeaders(request, authService.getToken(), ip);
        return next(newRequest).pipe(
          catchError((error) => catchRequestError(navigationService, alertService, error)),
        );
      }),
    );
  }

  return next(request);
};
