import { createReducer, on } from '@ngrx/store';

import { updateStatus } from '../actions/app-status.actions';
import { LeadStatusResponse } from '../../../shared/enums/lead-status';
import { AppState } from '../app.state';

export const initialState = {
  status: {} as LeadStatusResponse,
};

export const appStatusReducer = createReducer(
  initialState,
  on(updateStatus, (state, { status }): AppState => ({ ...state, status })),
);
