import { afterNextRender, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { RefreshHandlerService } from '@shared/services/refresh-handler.service';
import { RouterService } from '@shared/services/router.service';
import { IconModule } from '@ui/components/icon/icon.module';
import { DialogInfoModule } from '@ui/dialogs/dialog-info/dialog-info.module';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RedirectionModule } from './services/redirection/redirection.module';
import { ScriptLoaderService } from './services/script-loader.service';
import { WebServiceWorker } from './services/web-service-worker.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterModule,
    RedirectionModule,
    // Application
    DialogInfoModule,
  ],
  providers: [RouterService, RefreshHandlerService, ScriptLoaderService],
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription = new Subscription();

  /**
   * User interactions
   */
  private scriptsLoaded = false;

  /**
   * Functions to unlisten events
   */
  private unlistenFunctions: (() => void)[] = [];

  constructor(
    private router: Router,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly routerService: RouterService,
    private readonly refreshService: RefreshHandlerService,
    private readonly scriptLoader: ScriptLoaderService,
    private readonly renderer: Renderer2,
    private serviceWorker: WebServiceWorker,
  ) {
    // Register Icons
    IconModule.registerIcons(...IconModule.deps());

    // Add listeners for user's first event
    afterNextRender(() => this.addWindowListeners());
  }

  ngOnInit(): void {
    this.serviceWorker.$isAnyNewUpdateAvailable.subscribe(
      (updateAvailable) => updateAvailable && window.location.reload(),
    );

    // Segment
    this.scriptLoader.loadSegmentScript();
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  private loadScripts() {
    this.scriptsLoaded = true;
    this.scriptLoader.lazyLoadScripts();

    this.scriptLoader.loadGoogleAnalyticsScript();

    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event): event is NavigationStart | NavigationEnd =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: any) => {
        if (event instanceof NavigationStart)
          this.refreshService.refreshButtonWasClicked(!this.router.navigated);
        if (event instanceof NavigationEnd)
          this.googleAnalyticsService.sendPageView(event.urlAfterRedirects);
      });
    this.routerService.checkUrl();
  }

  /**
   * Add listeners to user's first move
   */
  addWindowListeners() {
    const events = ['scroll', 'click', 'mouseover'];

    const onFirstUserInteraction = () => {
      if (!this.scriptsLoaded) {
        this.loadScripts();
        this.removeWindowListeners();
      }
    };

    // By default, its load the scripts after 2s when the window finish load
    this.unlistenFunctions.push(
      this.renderer.listen(window, 'load', () => {
        setTimeout(() => {
          onFirstUserInteraction();
        }, 2000);
      }),
    );

    events.forEach((event) => {
      this.unlistenFunctions.push(this.renderer.listen(window, event, onFirstUserInteraction));
    });
  }

  removeWindowListeners() {
    this.unlistenFunctions.forEach((listener) => {
      listener();
    });
  }
}
