import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { GoogleAnalyticsService } from '@fundo/app/shared/services/google-analytics.service';

import { FooterModule } from '../../layout/footer/footer.module';
import { HeaderModule } from '../../layout/header/header.module';

interface TemplateConfig {
  showNavigation: boolean;
}

@Component({
  selector: 'fo-default-template',
  standalone: true,
  templateUrl: './default-template.component.html',
  styleUrls: ['./default-template.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    // Layout
    HeaderModule,
    FooterModule,
  ],
  providers: [GoogleAnalyticsService],
})
export class DefaultTemplateComponent implements OnInit {
  /**
   * Define whether show navigation on header and footer elements.
   */
  showNavigation: boolean;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.showNavigation = (
      this.activatedRoute.snapshot.data as TemplateConfig
    ).showNavigation;
  }
}