<nav foSegmentTrack class="nav" [ngClass]="isHeader ? 'nav--header' : 'nav--aside'">
  <ul class="menu" *ngIf="showElements">
    <li *ngFor="let item of navItems">
      <a
        class="menu__item"
        [routerLink]="item.link"
        [fragment]="item.link ? undefined : item.id"
        (click)="onNavItemClick(item.label)">
        {{ item.label }}</a
      >
    </li>

    @if (!isHeader) {
      <li *ngFor="let item of navTerms">
        <a class="menu__item" [href]="item.link" (click)="onNavItemClick(item.label)">
          {{ item.label }}</a
        >
      </li>
    }
  </ul>

  <a href="/apply-now?PID=8888">
    <ui-button
      *ngIf="showElements"
      data-testid="applyNowNavButton"
      class="nav__apply-now"
      size="nav"
      [isCTA]="true"
      (click)="onApplyNowClicked()">
      {{ TEXT.BUTTON_LABEL.APPLY }}
    </ui-button>
  </a>

  <ui-button
    data-testid="hamburguerNavButton"
    class="nav__sidenav-button"
    mode="icon"
    color="none"
    size="none"
    [ariaLabel]="isMenuOpen ? 'Close menu' : 'Open menu'"
    (onClick)="sidenavButtonClicked()"
    *ngIf="isHeader && !showElements">
    <!----- Hamburger icon ----->
    <div class="icon-menu" [ngClass]="!isMenuOpen ? 'icon-hamburguer' : 'icon-close'">
      <div
        [@hamburguerX]="{
          value: !isMenuOpen ? 'hamburguer' : 'topX',
          params: { margin: smallIcon ? '5px' : '10px' }
        }"></div>
      <div
        [@hamburguerX]="{
          value: !isMenuOpen ? 'hamburguer' : 'hide'
        }"></div>
      <div
        [@hamburguerX]="{
          value: !isMenuOpen ? 'hamburguer' : 'bottomX',
          params: { margin: smallIcon ? '5px' : '10px' }
        }"></div>
    </div>
  </ui-button>

  <ui-md-text *ngIf="!isHeader" class="nav__help" [markdownText]="aside"></ui-md-text>
</nav>
