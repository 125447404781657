import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeniedManager } from '../classes/denied-manager.class';
import { LeadService } from '../services/lead.service';
import { RedirectionService } from '../services/redirection/redirection.service';
import { LeadStatusResponse } from '../shared/enums/lead-status';
import { FoPageRoute } from '../shared/services/navigation.service';
import { LeadStatusRedirectToPage } from '../constants/lead-status-page';

export function StatusGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> {
  const leadService = inject(LeadService);
  const redirectionService = inject(RedirectionService);

  // If error page, bypass status verification
  if (FoPageRoute.ErrorPage === state.url) {
    return of(true);
  }

  return leadService.getStatus().pipe(
    map((response: LeadStatusResponse) => {
      const deniedManager = new DeniedManager();
      if (
        (state.url === FoPageRoute.DeniedPageFinancial &&
          deniedManager.isFinancialDeniedApplication(response?.denialReason || '')) ||
        LeadStatusRedirectToPage[response.id] === state.url
      ) {
        return true;
      }
      redirectionService.redirectAccordingToStatus(response.id, response as any);
      return false;
    }),
  );
}
