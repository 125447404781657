const Breakpoints = {
  'xxx-small': 320,
  'xx-small': 375,
  'x-small': 480,
  'small': 768,
  // 'medium': 840,
  // 'large': 960,
  'x-large': 1024,
  // 'xx-large': 1280,
  'xxx-large': 1440,
  // 'high-quality': 1700,
  'x-high-quality': 1920,
};

export const MediaQueries = {
  untilSmall: `(max-width: ${Breakpoints.small - 1}px)`,
  untilXLarge: `(max-width: ${Breakpoints['x-large'] - 1}px)`,
  fromSmall: `(min-width: ${Breakpoints.small}px)`,
  fromXSmall: `(min-width: ${Breakpoints['x-small']}px)`,
  fromXxxLarge: `(min-width: ${Breakpoints['xxx-large']}px)`,
};
