export const socialMedia = [
  {
    id: 'instagram',
    href: 'https://instagram.com/fundo_llc?igshid=1exrglx6xxssb',
    target: '_blank',
    icon: 'brand-instagram-outline',
    alt: 'Find us on Instagram',
  },
  {
    id: 'facebook',
    href: 'https://www.facebook.com/Fundo-LLC-115005536559629/',
    target: '_blank',
    icon: 'brand-facebook-outline',
    alt: 'Stay social with us on Facebook',
  },
  {
    id: 'linkedin',
    href: 'https://www.linkedin.com/company/fundo-llc/',
    target: '_blank',
    icon: 'brand-linkedin-outline',
    alt: 'Connect with us on LinkedIn for professional updates',
  },
];
