<header
  class="principal-header"
  [ngClass]="{
    'principal-header--show-navigation': showNavigation
  }">
  <div class="principal-header__container">
    <span class="principal-header__logo">
      <a
        class="any"
        [routerLink]="showNavigation ? '/' : null"
        alt="Fundo Logo">
        <picture>
          <source
            type="image/webp"
            srcset="
              assets/optimized/logo-fundo-90w.webp  480w,
              assets/optimized/logo-fundo-115w.webp 768w,
              assets/optimized/logo-fundo-162w.webp
            " />
          <img
            [ngSrc]="'optimized/logo-fundo-162w.png'"
            srcset="
              assets/optimized/logo-fundo-90w.png  480w,
              assets/optimized/logo-fundo-115w.png 768w,
              assets/optimized/logo-fundo-162w.png
            "
            alt="Fundo Logo"
            height="38"
            width="152" />
        </picture>
      </a>
    </span>
    <ui-nav
      class="principal-header__nav"
      *ngIf="showNavigation"
      [isHeader]="true"
      [isMenuOpen]="sidenav.opened"
      (sidenavToggle)="toggleSidenav($event)"></ui-nav>
  </div>
</header>
<mat-sidenav-container class="container" [hasBackdrop]="true">
  <mat-sidenav-content>
    <div #scrollTarget>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    #sidenav
    class="sidenav"
    mode="over"
    position="end"
    [fixedInViewport]="true"
    [fixedTopGap]="0">
    <ui-nav (sidenavToggle)="toggleSidenav($event)"></ui-nav>
  </mat-sidenav>
</mat-sidenav-container>
