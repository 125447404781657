import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@ui/components/icon/icon.module';

import { SocialLinksComponent } from './social-links.component';

@NgModule({
  declarations: [SocialLinksComponent],
  imports: [CommonModule, IconModule],
  exports: [SocialLinksComponent],
})
export class SocialLinksModule {}
