export type NavigationLinkTypes =
  | 'HOME'
  | 'REVIEWS'
  | 'HOW_IT_WORKS'
  | 'WHY_FUNDO'
  | 'FAQS'
  | 'CONTACT'
  | 'PRIVACY_POLICY'
  | 'E_SIGN'
  | 'TERMS_OF_SERVICE';

export const NAVIGATION = {
  LINK: {
    HOME: {
      LABEL: 'Home',
      ALT: 'Home',
    },
    REVIEWS: {
      LABEL: 'Reviews',
      ALT: 'Reviews',
    },
    HOW_IT_WORKS: {
      LABEL: 'How it works',
      ALT: 'How it works',
    },
    WHY_FUNDO: {
      LABEL: 'Why Fundo',
      ALT: 'Reasons to Choose Us',
    },
    FAQS: {
      LABEL: 'FAQs',
      ALT: 'Frequently Asked Questions',
    },
    CONTACT: {
      LABEL: 'Contact',
      ALT: 'Get in Touch',
    },
    E_SIGN: {
      LABEL: 'Esign',
      ALT: 'Esign Contract',
    },
    MESSAGING_TERMS: {
      LABEL: 'Messaging Terms',
      ALT: 'Messaging Terms',
    },
    PRIVACY_POLICY: {
      LABEL: 'Privacy Policy',
      ALT: 'Privacy Policy',
    },
    TERMS_OF_SERVICE: {
      LABEL: 'Terms and Conditions',
      ALT: 'Terms of service',
    },
  },
};
