import { createReducer, on } from '@ngrx/store';
import { UserState } from '../user.state';
import { updateUserIp } from '../actions/user.action';

export const initialState: UserState = {
  ip: '',
};

export const userReducer = createReducer(
  initialState,
  on(updateUserIp, (state, { ip }): UserState => ({ ...state, ip: ip || '' })),
);
