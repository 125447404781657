import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { hamburgerIcon } from '@fundo/app/constants/animations';
import { MediaQueries } from '@fundo/app/constants/breakpoints';
import { Link, links } from '@fundo/app/constants/navigation';
import { MediaQueryService } from '@fundo/app/services/media-query.service';
import {
  GAEventCategory,
  GAPageType,
  GoogleAnalyticsService,
} from '@shared/services/google-analytics.service';
import { NavigationService } from '@shared/services/navigation.service';
import { SidenavAction } from '@ui/layout/header/header.component';

import { aside, NAV_TEXT } from './content';
import { SegmentEvent } from '@fundo/app/constants/segment-tracking';

@Component({
  selector: 'ui-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [hamburgerIcon()],
})
export class NavComponent implements AfterViewInit {
  /**
   * Step's texts
   */
  readonly TEXT = NAV_TEXT;

  /**
   * Dynamic content
   */
  readonly aside = aside;

  /**
   * Determine if the parent element is a header
   * @default false
   */
  @Input() isHeader: boolean = false;

  /**
   * Determine if menu is opened
   * @default true
   */
  @Input() isMenuOpen: boolean;

  /**
   * Navigation links
   */
  navItems: Link[] = links.nav;

  /**
   * Navigation links
   */
  navTerms: Link[] = links.terms;

  /**
   * Determine if menu and button elements are present
   * @default false
   */
  showElements: boolean;

  /**
   * Is small icon
   */
  smallIcon: boolean = false;

  /**
   * Navigation button clicked
   */
  @Output() sidenavToggle: EventEmitter<SidenavAction> = new EventEmitter();

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private mediaQueryService: MediaQueryService,
    private navigationService: NavigationService,
  ) {}

  ngAfterViewInit(): void {
    this.setMobileVariables();
  }

  /**
   * Emit event
   */
  sidenavButtonClicked(): void {
    this.sidenavToggle.emit(SidenavAction.toggle);
  }

  /**
   * Register analitycs event on navigation item click
   * @param itemLabel property label on .json file
   */
  onNavItemClick(itemLabel: string): void {
    this.googleAnalyticsService.logClick(GAEventCategory.MenuItem, GAPageType.Header, itemLabel);
    if (!this.isHeader) this.sidenavToggle.emit(SidenavAction.close);
  }

  /**
   * Register analytic event on button click
   */
  onApplyNowClicked(): void {
    this.googleAnalyticsService.logClick(GAEventCategory.ApplyNow, GAPageType.Header);
    this.navigationService.navigateToApplyNowFormOrganic();

    // Segment
    window.analytics.track(SegmentEvent.ApplyNowButtonClicked);
  }

  /**
   *
   */
  private setMobileVariables() {
    this.mediaQueryService
      .matches(MediaQueries.untilSmall)
      .subscribe((matches) => (this.smallIcon = matches));

    this.mediaQueryService.matches(MediaQueries.fromXxxLarge).subscribe((matches) => {
      this.showElements = this.isHeader ? matches : true;
      this.showElements && this.sidenavToggle.emit(SidenavAction.close);
    });
  }
}
