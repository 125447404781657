import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { IpInfoService } from '../shared/services/ip-info.service';
import { NavigationService } from '../shared/services/navigation.service';

export const wrongIpGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const ipInfoService = inject(IpInfoService);
  const navigationService = inject(NavigationService);

  // Validate IP
  return ipInfoService.validateIpWithToken().pipe(
    map((accessAllowed: boolean) => {
      if (!accessAllowed) {
        navigationService.navigateToWrongIpPage();
        authService.clearCurrentSession();
      }
      return accessAllowed;
    }),
  );
};
