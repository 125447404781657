<ul class="social-media" [ngClass]="classes">
  <li class="social-media__item" *ngFor="let item of socialMediaList">
    <a
      [href]="item.href"
      [target]="item.target"
      [attr.title]="item.alt"
      (click)="onSocialMediaClick(item.id)">
      <ui-icon
        [icon]="item.icon"
        [color]="theme === 'dark' ? 'white' : 'black'"
        size="small"></ui-icon>
    </a>
  </li>
</ul>
