import { afterNextRender, Component, Input, ViewEncapsulation } from '@angular/core';
import { MediaQueries } from '@fundo/app/constants/breakpoints';
import { Link, links } from '@fundo/app/constants/navigation';
import { MediaQueryService } from '@fundo/app/services/media-query.service';
import { ScriptLoaderService } from '@fundo/app/services/script-loader.service';
import {
  GAEventCategory,
  GAPageType,
  GoogleAnalyticsService,
} from '@shared/services/google-analytics.service';
import { FUNDO } from '@ui/i18n/en/fundo';

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  /**
   * Fundo information
   */
  readonly FUNDO = FUNDO;

  /**
   * List of available media queries
   */
  readonly MediaQueries = MediaQueries;

  /**
   * Navigation links
   * Remove Home link
   */
  readonly navItems: Link[] = links.nav;

  /**
   * Navigation links
   * Remove Home link
   */
  readonly termsItems: Link[] = links.terms;

  /**
   * Determines whether the navigation elements are shown or not
   */
  @Input()
  showNavigation?: boolean = true;

  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly scriptLoader: ScriptLoaderService,
    readonly mediaQueryService: MediaQueryService,
  ) {
    afterNextRender(() => this.scriptLoader.loadGodaddyScript());
  }

  onFooterMenuItemClick(menuItem: string): void {
    this.googleAnalyticsService.logClick(GAEventCategory.MenuItem, GAPageType.Footer, menuItem);
  }

  onPageClick(page: string): void {
    this.googleAnalyticsService.logClick(GAEventCategory.PagesItem, GAPageType.MainPage, page);
  }
}
