import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MediaQueryService } from '@fundo/app/services/media-query.service';
import { ScriptLoaderService } from '@fundo/app/services/script-loader.service';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';

import { FooterAddressModule } from './components/footer-address/footer-address.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    FooterAddressModule,
    // Shared
    NgOptimizedImage,
  ],
  exports: [FooterComponent],
  providers: [GoogleAnalyticsService, ScriptLoaderService, MediaQueryService],
})
export class FooterModule {}
