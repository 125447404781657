import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideState, provideStore } from '@ngrx/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { routes } from './app.routes';
import { LeadStatusInterceptor } from './interceptors/lead-status.interceptor';
import { tokenInterceptorFn } from './interceptors/token.interceptor';
import { appStatusReducer } from './states/app-status/reducer/app-status.reducer';
import { AuthService } from './services/auth.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { RedirectionService } from './services/redirection/redirection.service';
import { WebServiceWorker } from './services/web-service-worker.service';
import { DialogNotificationModule } from './shared/dialogs/dialog-notification/dialog-notification.module';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { NavigationService } from './shared/services/navigation.service';
import { RefreshHandlerService } from './shared/services/refresh-handler.service';
import { RouterService } from './shared/services/router.service';
import { userReducer } from './states/user/reducer/user.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideClientHydration(),
    provideAnimations(),
    // Shared
    provideEnvironmentNgxMask(),
    provideHttpClient(
      withFetch(),
      withInterceptors([tokenInterceptorFn]),
      withInterceptorsFromDi(),
    ),
    // ngrx
    provideStore({ app: appStatusReducer, user: userReducer }),
    // External dependencies config
    importProvidersFrom(DialogNotificationModule),
    AuthService,
    GoogleAnalyticsService,
    RefreshHandlerService,
    RouterService,
    NavigationService,
    RedirectionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LeadStatusInterceptor,
      multi: true,
    },
    {
      provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        hideRequiredMarker: true,
      },
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    // Service worker
    provideServiceWorker('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    WebServiceWorker,
  ],
};
