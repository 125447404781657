import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@ui/components/button/button.module';
import { NavModule } from '@ui/layout/nav/nav.module';

import { HeaderComponent } from './header.component';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    // Components
    ButtonModule,
    NavModule,
    //
    MatSidenavModule,
    //
    NgOptimizedImage,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
