import { BUTTON_LABEL_TEXT } from '@ui/i18n/en/button';

export const NAV_TEXT = {
  BUTTON_LABEL: BUTTON_LABEL_TEXT,
};

export const aside = `Questions?

We are here to help

![Contact us](assets/svg/phone-white.svg) [(866) 393-8636](tel:(866)393-8636)
`;
