export const FUNDO = {
  ADDRESS: '3323 NE 163rd Street Suite 509, North Miami Beach, FL 33160',
  CONTACT1: {
    LABEL: '(866)EZ-FUNDO',
    HREF: 'tel:8663938636',
  },
  CONTACT2: {
    LABEL: '(866) 393-8636',
    HREF: 'tel:8663938636',
  },
  EMAIL: {
    LABEL: 'support@fundo.com',
    HREF: 'mailto:support@fundo.com',
  },
  SOCIAL: {
    INSTAGRAM: {
      HREF: 'https://instagram.com/fundo_llc?igshid=1exrglx6xxssb',
      ALT: 'Instagram',
    },
    FACEBOOK: {
      HREF: 'https://www.facebook.com/Fundo-LLC-115005536559629/',
      ALT: 'Facebook',
    },
    LINKEDIN: {
      HREF: 'https://www.linkedin.com/company/fundo-llc/',
      ALT: 'LinkedIn',
    },
  },
  COPYRIGHT: '© 2019 Fundo | All Rights Reserved',
};
