import { Component, EventEmitter, Input, Output } from '@angular/core';
import { socialMedia } from './social-media';

export enum Size {
  Lg = 'lg',
}

@Component({
  selector: 'ui-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
})
export class SocialLinksComponent {
  /**
   * links thme
   */
  @Input()
  public theme: 'light' | 'dark' = 'dark';

  /**
   * content alignment
   */
  @Input()
  public alignContent: 'center' | 'left' = 'center';

  /**
   *
   */
  @Input()
  public size: Size;

  /**
   *
   */
  @Output() public socialMediaClick: EventEmitter<string> = new EventEmitter();

  /**
   * Social media list
   */
  readonly socialMediaList = socialMedia;

  constructor() {}

  public onSocialMediaClick(socialMedia: string): void {
    this.socialMediaClick.emit(socialMedia);
  }

  public get classes(): string[] {
    return [
      this.size ? `social-media--${this.size}` : '',
      `social-media--theme-${this.theme}`,
      `social-media--${this.alignContent}`,
    ];
  }
}
