import { NAVIGATION, NavigationLinkTypes } from '@ui/i18n/en/navigation';
import { HomeLinks } from './pages';
import { termPages } from './term-pages';

export interface Link {
  id: string;
  label: string;
  alt: string;
  link: string;
}

/**
 * Get page information given the page id and url
 * @param id
 * @param link
 * @returns
 */
const getPageInfo = (id: string, link: string = ''): Link => {
  const pageKey = id.replace(/-/g, '_').toUpperCase() as NavigationLinkTypes;
  return { id, label: NAVIGATION.LINK[pageKey].LABEL, alt: NAVIGATION.LINK[pageKey].ALT, link };
};

export const links: { nav: Link[]; terms: Link[] } = {
  nav: Object.values(HomeLinks).map((id: string) => getPageInfo(id)),
  terms: termPages.map(({ id, link }) => getPageInfo(id, link)),
};
