import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { SocialLinksModule } from '@ui/components/social-links/social-links.module';

import { FooterAddressComponent } from './footer-address.component';

@NgModule({
  declarations: [FooterAddressComponent],
  imports: [
    CommonModule,
    // Common
    SocialLinksModule,
    // Shared
    NgOptimizedImage,
  ],
  exports: [FooterAddressComponent],
  providers: [GoogleAnalyticsService],
})
export class FooterAddressModule {}
