<footer class="footer">
  <div class="footer__bg">
    @defer (on viewport; on timer(2s)) {
      <picture>
        <!-- WebP format -->
        <source
          type="image/webp"
          media="(max-width: 320px)"
          srcset="assets/optimized/bg-footer-small-320w.webp" />
        <source
          type="image/webp"
          media="(min-width: 321px) and (max-width: 375px)"
          srcset="assets/optimized/bg-footer-small-375w.webp" />
        <source
          type="image/webp"
          media="(min-width: 376px) and (max-width: 480px)"
          srcset="assets/optimized/bg-footer-small-480w.webp" />
        <source
          type="image/webp"
          media="(min-width: 481px) and (max-width: 768px)"
          srcset="assets/optimized/bg-footer-large-768w.webp" />
        <source
          type="image/webp"
          media="(min-width: 769px) and (max-width: 1024px)"
          srcset="assets/optimized/bg-footer-large-1024w.webp" />
        <source
          type="image/webp"
          media="(min-width: 1025px) and (max-width: 1920px)"
          srcset="assets/optimized/bg-footer-large-1920w.webp" />
        <source
          type="image/webp"
          media="(min-width: 1921px)"
          srcset="assets/optimized/bg-footer-large-3840w.webp" />
        <!-- JPEG format -->
        <source
          type="image/jpeg"
          media="(max-width: 320px)"
          srcset="assets/optimized/bg-footer-small-320w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 321px) and (max-width: 375px)"
          srcset="assets/optimized/bg-footer-small-375w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 376px) and (max-width: 480px)"
          srcset="assets/optimized/bg-footer-small-480w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 481px) and (max-width: 768px)"
          srcset="assets/optimized/bg-footer-large-768w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 769px) and (max-width: 1024px)"
          srcset="assets/optimized/bg-footer-large-1024w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 1025px) and (max-width: 1920px)"
          srcset="assets/optimized/bg-footer-large-1920w.jpeg" />
        <source
          type="image/jpeg"
          media="(min-width: 1921px)"
          srcset="assets/optimized/bg-footer-large-3840w.jpeg" />
        <!-- FALLBACK IMAGES -->
        <img
          *ngIf="mediaQueryService.matches(MediaQueries.untilSmall) | async"
          ngSrc="assets/optimized/bg-footer-small-480w.jpeg"
          alt=""
          width="480"
          height="400" />
        <img
          *ngIf="mediaQueryService.matches(MediaQueries.fromSmall) | async"
          ngSrc="assets/optimized/bg-footer-large-768w.jpeg"
          alt=""
          width="768"
          height="340" />
      </picture>
    } @placeholder {
      <span></span>
    }
  </div>

  <div class="footer__bg-overlay"></div>

  <div class="footer__wrapper">
    <div class="footer__content">
      <div>
        <figure class="menu" *ngIf="showNavigation">
          <figcaption class="menu__title">Menu</figcaption>
          <ul title="Menu">
            <li *ngFor="let item of navItems">
              <a
                class="menu__item"
                [routerLink]="item.link"
                [fragment]="item.id"
                (click)="onFooterMenuItemClick(item.label)">
                {{ item.label }}</a
              >
            </li>
          </ul>
        </figure>

        <ui-footer-address class="address"></ui-footer-address>

        <figure class="menu" *ngIf="showNavigation">
          <figcaption class="menu__title">Pages</figcaption>
          <ul title="Pages">
            <li *ngFor="let item of termsItems">
              <a class="menu__item" [href]="item.link" (click)="onPageClick(item.label)">
                {{ item.label }}</a
              >
            </li>
          </ul>
        </figure>
      </div>
      <div class="copyright">
        <p>{{ FUNDO.COPYRIGHT }}</p>
        <span>
          <div id="siteseal"></div>
        </span>
      </div>
    </div>
  </div>
</footer>
