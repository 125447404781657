import { Component } from '@angular/core';

import {
  GAEventCategory,
  GAPageType,
  GoogleAnalyticsService,
} from '@shared/services/google-analytics.service';
import { FUNDO } from '@ui/i18n/en/fundo';

@Component({
  selector: 'ui-footer-address',
  templateUrl: './footer-address.component.html',
  styleUrls: ['./footer-address.component.scss'],
})
export class FooterAddressComponent {
  /**
   * Fundo information
   */
  readonly FUNDO = FUNDO;

  constructor(private readonly googleAnalyticsService: GoogleAnalyticsService) {}

  public onSocialMediaClick(socialMedia: string): void {
    this.googleAnalyticsService.logClick(
      GAEventCategory.SocialMedia,
      GAPageType.Footer,
      socialMedia,
    );
  }
}
