import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { NavigationService } from '@shared/services/navigation.service';
import { ButtonModule } from '@ui/components/button/button.module';
import { IconModule } from '@ui/components/icon/icon.module';
import { MdTextComponent } from '@ui/components/md-text/md-text.component';

import { NavComponent } from './nav.component';
import { SegmentTrackDirective } from '@fundo/app/directives/segment-tracking.directive';

@NgModule({
  declarations: [NavComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    IconModule,
    MdTextComponent,
    // Directives
    SegmentTrackDirective
  ],
  exports: [NavComponent],
  providers: [GoogleAnalyticsService, NavigationService],
})
export class NavModule {}
