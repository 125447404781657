import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService implements OnDestroy {
  /**
   * Destroy indicator
   */
  private readonly destroy$: Subject<void> = new Subject<void>();

  /**
   * Observable which reviews the acomplishment of breakpoints
   */
  observer$: Observable<BreakpointState>;

  constructor(readonly breakpointObserver: BreakpointObserver) {}

  /**
   * Creates an observable to listen window size
   * @param mediaQuery
   * @returns
   */
  matches(mediaQuery: string): Observable<boolean> {
    return this.breakpointObserver.observe([mediaQuery]).pipe(
      takeUntil(this.destroy$),
      map((result: BreakpointState) => result.matches),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
