import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

export enum SidenavAction {
  toggle,
  close,
}

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements AfterViewInit {
  /**
   * Determines whether the navigation elements are shown or not
   */
  @Input()
  showNavigation?: boolean = true;

  /**
   * Sidenav element
   */
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor() {}

  ngAfterViewInit(): void {
    this.sidenav.opened = false;
  }

  /**
   * Toogle sidenav
   */
  toggleSidenav(sidenavAction: SidenavAction) {
    if (this.sidenav) {
      if (sidenavAction === SidenavAction.toggle) this.sidenav.toggle();
      if (sidenavAction === SidenavAction.close) this.sidenav.opened && this.sidenav.close();
    }
  }
}
