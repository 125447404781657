<address>
  <div class="address__data">
    <picture>
      <source
        type="image/webp"
        srcset="
          assets/optimized/logo-fundo-115w.webp 768w,
          assets/optimized/logo-fundo-210w.webp
        " />
      <img
        class="data__logo"
        [ngSrc]="'optimized/logo-fundo-210w.png'"
        srcset="
          assets/optimized/logo-fundo-115w.png 768w,
          assets/optimized/logo-fundo-210w.png
        "
        alt="Fundo Logo"
        height="70"
        width="210" />
    </picture>
    <p>{{ FUNDO.ADDRESS }}</p>
    <a
      class="data__link email"
      [href]="FUNDO.EMAIL.HREF"
      >{{ FUNDO.EMAIL.LABEL }}</a
    >
    <a
      class="data__link contact2"
      [href]="FUNDO.CONTACT2.HREF"
      >{{ FUNDO.CONTACT2.LABEL }}</a
    >
    <a
      class="data__link contact1"
      [href]="FUNDO.CONTACT1.HREF"
      >{{ FUNDO.CONTACT1.LABEL }}</a
    >
    <ui-social-links
      class="data__social-links"
      (socialMediaClick)="onSocialMediaClick($event)"></ui-social-links>
  </div>

  <a
    id="bbblink"
    class="sevtbum address__bbb-logo"
    href="https://www.bbb.org/us/fl/north-miami-beach/profile/financial-services/fundo-0633-90575447#bbbseal"
    title="Fundo, LLC is a BBB Accredited Financial Service in North Miami Beach, FL"
    style="position: absolute">
    <img
      id="bbblinkimg"
      width="131"
      height="32"
      ngSrc="https://seal-seflorida.bbb.org/logo/sevtbum/fundo-90575447.png"
      width="120"
      height="98"
      alt="Fundo, LLC is a BBB Accredited Financial Service in North Miami Beach, FL"
  /></a>
  <script defer type="text/javascript">
    var bbbprotocol =
      'https:' == document.location.protocol ? 'https://' : 'http://';
    (function () {
      var s = document.createElement('script');
      s.src =
        bbbprotocol +
        'seal-seflorida.bbb.org' +
        unescape('%2Flogo%2Ffundo-90575447.js');
      s.type = 'text/javascript';
      s.defer = true;
      var st = document.getElementsByTagName('script');
      st = st[st.length - 1];
      var pt = st.parentNode;
      pt.insertBefore(s, pt.nextSibling);
    })();
  </script>
</address>
